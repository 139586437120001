import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [file, setFile] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [endpoint, setEndpoint] = useState('/ocr');
  const [response, setResponse] = useState(null);
  const [preview, setPreview] = useState(null);
  const [preprocess, setPreprocess] = useState(false); // State to handle preprocessing option

  const handleFileChange = event => {
    const file = event.target.files[0];
    setFile(file);
    setPreview(URL.createObjectURL(file)); // Create a URL for the file
  };

  const handleApiKeyChange = event => {
    setApiKey(event.target.value);
  };

  const handleEndpointChange = event => {
    setEndpoint(event.target.value);
  };

  const handlePreprocessChange = () => {
    setPreprocess(!preprocess); // Toggle the preprocessing option
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', file);

    try {
      const url = `${process.env.REACT_APP_API_URL}${endpoint}${endpoint.includes('/predict') || endpoint.includes('/combined-inference') ? '' : '?preprocess=' + preprocess}`;
      const res = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': apiKey
        }
      });

      if (res.data.status === 'success') {
        setResponse(JSON.stringify(res.data.data, null, 2));
      } else {
        setResponse(`Error: ${res.data.statusReason}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setResponse('Failed to process the image');
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
        <h1>OCR Test Form</h1>
        <div>
          <label>API Key:</label>
          <input type="text" value={apiKey} onChange={handleApiKeyChange} />
        </div>
        <div>
          <label>Choose endpoint:</label>
          <select value={endpoint} onChange={handleEndpointChange}>
            <option value="/ocr">/ocr</option>
            <option value="/ocr/distance">/ocr/distance</option>
            <option value="/predict">/predict</option>
            <option value="/combined-inference">/combined-inference</option> {/* New combined inference endpoint */}
          </select>
        </div>
        <div>
          <label>Preprocess Image:</label>
          <input type="checkbox" checked={preprocess} onChange={handlePreprocessChange} />
        </div>
        <div>
          <label>Upload image:</label>
          <input type="file" onChange={handleFileChange} />
          {preview && <img src={preview} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
        </div>
        <button type="submit" style={{ marginTop: '10px' }}>Send Image</button>
      </form>
      {response && (
        <div>
          <h3>Response:</h3>
          <pre>{response}</pre>
        </div>
      )}
    </div>
  );
}

export default App;
